/**
    Implements a basic styles for:
    https://reactcommunity.org/react-transition-group/css-transition
*/
/* .position-item-enter {
    opacity: 0;
}
.position-item-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
} */
.position-item-exit {
    opacity: 1;
}
.position-item-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}