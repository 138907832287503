.bg-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    font-size: 40px;
    z-index: 100;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;

    transition-delay: 0s;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: linear;

    .main-loader {
      margin: 8px;
    }

    &.local-loader {
      position: absolute;
      z-index: 10;
      .main-loader {
        transform: scale(0.5);
      }
    }
  }

